export const ROUTES = {
  HOME: "/",
  CREATE_ORDER: "/create-order",
  MOTIVATIONAL_VIDEOS: "/motivational-videos",
};

export const COLORS = {
  PRIMARY: "#42a5f5",
  SECONDARY: "#ff8a84",
};

export const BLANK_IMAGE =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAQAAACTbf5ZAAAAjElEQVR42u3PQQEAAAQEMNc/rQRU8Lc1WHrqlQgLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLC18t21bo+bMlxdcAAAAASUVORK5CYII=";
