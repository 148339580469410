import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";

import { metaList } from "./meta-list";

const MetaTags = ({ children, baseUrlsProp, title }) => {
  const { pathname } = useLocation();
  const currentLang = "ru";

  let base_url = useMemo(() => {
    const base_urls = baseUrlsProp;
    if (base_urls.hasOwnProperty(currentLang)) {
      return base_urls[currentLang];
    }
    return base_urls["en"];
  }, [currentLang, baseUrlsProp]);

  const tags = useMemo(() => {
    if (metaList.hasOwnProperty(pathname) && metaList[pathname].hasOwnProperty(currentLang)) {
      return metaList[pathname][currentLang];
    }
    return metaList["/"]["en"];
  }, [currentLang, pathname]);

  const locale = useMemo(() => {
    const localesList = { en: "en_EN", ru: "ru_RU" };
    if (localesList.hasOwnProperty(currentLang)) {
      return localesList[currentLang];
    }
    return localesList["en"];
  }, [currentLang]);

  return (
    <Helmet>
      <title>{`${title} | ${tags?.title ? tags?.title : "404"}`}</title>
      <meta name="description" content={tags?.desc} />
      <meta name="keywords" content={tags?.keys} />
      <meta property="og:locale" content={locale} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={`${title} | ${tags?.title}`} />
      <meta property="og:description" content={tags?.desc} />
      <meta property="og:image" content={`${base_url}${tags?.img}`} />
      <meta property="og:url" content={`${base_url}${tags?.url}`} />
      <meta name="robots" content={tags?.hasOwnProperty("robots") ? tags?.robots : "index, follow"} />
      <link rel="canonical" href={`${base_url}${tags?.url}`} />
      <link rel="shortcut icon" href={tags?.favicon} />

      {children}
    </Helmet>
  );
};

export default MetaTags;
