import React from "react";
import { CircularProgress } from "@mui/material";
import classNames from "classnames";

import "./full-page-loader.scss";

const FullPageLoader = ({ withOverlay }) => {
  return (
    <div className={classNames("fullPageLoader", { fullPageLoader_withOverlay: withOverlay })}>
      <CircularProgress color="secondary" size={120} />
    </div>
  );
};

export default FullPageLoader;
